<template>
  <v-app style="background-color: #F3F3F3;">
    <v-app-bar app color="blue" dark>
      <span class="mr-2">Painel de Clientes</span>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  async created() {
    if (this.isDesenvolvimento) {
      return;
    }

    if (!this.isRevenda) {
      return;
    }
  },
};
</script>

<style>
body {
  background-color: #F3F3F3;
}
</style>