export const checarSessao = () => {
  let expirada = false;
  let minutos = 0;
  /**
   *
   */
  let token_exp = localStorage.getItem("token_exp");
  if (token_exp) {
    let now = new Date();
    token_exp = new Date(token_exp);
    /**
     *
     */
    let diferenca = Math.abs(token_exp.getTime() - now.getTime());
    minutos = Math.round(((diferenca % 86400000) % 3600000) / 60000);
    if (minutos >= 30) {
      expirada = true;
    }
  } else {
    expirada = true;
  }

  if (expirada) {
    console.info("[SESSÃO] Sessão do navegador expirada, saindo...");
  } else {
    console.info(`[SESSÃO] Sessão ativa a ${minutos} minutos.`);
  }

  return expirada;
};

export const encerrar = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("token_exp");
  localStorage.removeItem("gh_token");
  /**
   *
   */
  window.location.href = "/";
};

export const verificarSessao = () => {
  const expirou = checarSessao();
  if (expirou) {
    return encerrar();
  }
};

export const getMes = (opc) => {
  const data_atual = new Date();

  if (opc) {
    data_atual.setMonth(data_atual.getMonth() + opc);
  }

  const mes_retorno = data_atual.getMonth() + 1;

  return `${mes_retorno}`.padStart(2, 0);
};
